import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import BannersImg from "../../components/images/advertising/banner"

export default function Banners() {
    return (
        <>
            <Layout>
                <SEO title="Banners" />
                <Display
                    title="Banners"
                    description="Banners are good for business! Not only are they durable & cost-effective, they are easy to transport and set up."
                    prev="/advertising/signs"
                    next="/advertising/table-drapes"
                    image={<BannersImg />}
                />
            </Layout>                
        </>
    )
}
